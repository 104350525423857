<template>
    <b-modal
        id="vr-game-intro-modal"
        ref="vr-game-intro-modal"
        class="modal-content"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <div class="header-container">
            <b-button class="close-button" @click="hideModal"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>

            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <img :src="require('@/assets/images/modal-icons/vr-game-icon.png')" alt="vr game icon">
                </b-col>
            </b-row>
        </div>

        <div class="footer-container">
            <div>
                <div class="body-head">
                    {{ popup.codeInstructions }}
                </div>

                <div
                    v-if="otpCode.length > 0"
                    class="body-text"
                    :class="{ 'expired': noTimeLeft }"
                >
                    {{ otpCode }}
                </div>
                <div v-else class="otp-info my-4">
                    <b-spinner variant="primary" />
                </div>
                <div v-if="errorMessageToShow" class="error-container">
                    <span class="error-message">{{ errorMessageToShow }}</span>
                </div>
                <div v-if="!errorMessageToShow" class="otp-info">
                    {{ popup.codeSendInfo }}
                </div>
                <div class="buttons-container">
                    <span class="time-left">
                        {{ timeLeft }}
                    </span>
                    <div
                        class="custom-btn"
                        :class="{ 'disabled': isNewCodeDisabled }"
                        @click="getNewCode"
                    >
                        {{ popup.codeButtonText }}
                    </div>
                    <span
                        v-if="isTaskCompleted"
                        class="go-back"
                        @click="hideModal"
                    >
                        {{ popup.goBackText }}
                    </span>

                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import emitter from '@/utils/emitter'
    import taskService from '@/services/task-service'
    export default {
        data() {
            return {
                otpCode: '',
                loadingOtp: false,
                otpInterval: null,
                secondsLeft: 0,
                errorMessageToShow: '',
                resultInterval: null,
                isTaskCompleted: false,
                popup: {
                    codeInstructions: 'Use this code in the VR headset to play the game:',
                    codeSendInfo: 'You have also received this code in your preferred communications channel',
                    codeButtonText: 'Get code again',
                    goBackText: 'Go back'
                }
            }
        },
        computed: {
            timeLeft() {
                return `${this.minutes} : ${this.seconds} `
            },
            minutes() {
                return String(Math.floor(this.secondsLeft / 60)).padStart(2, '0')
            },
            seconds() {
                return String(this.secondsLeft % 60).padStart(2, '0')
            },
            taskId() {
                return parseInt(this.$route.params.taskId)
            },
            missionId() {
                return parseInt(this.$route.params.missionId)
            },
            task() {
                return this.$store.getters.getTask(this.missionId, this.taskId)
            },
            mission() {
                return this.$store.getters.getMission(this.missionId)
            },
            isNewCodeDisabled() {
                return this.secondsLeft > 0 || !this.otpCode
            },
            noTimeLeft() {
                return this.secondsLeft === 0
            },
            isPlayAgainEnabled() {
                return this.$store.getters.isPlayAgainEnabled
            }
        },
        watch: {
            task: {
                handler(newValue) {
                    if (newValue?.configuration) {
                        const popupConfig = JSON.parse(newValue?.configuration)
                        this.popup.codeInstructions = popupConfig?.codeInstructions || this.popup.codeInstructions
                        this.popup.codeSendInfo = popupConfig?.codeSendInfo || this.popup.codeSendInfo
                        this.popup.codeButtonText = popupConfig?.codeButtonText || this.popup.codeButtonText
                        this.popup.goBackText = popupConfig?.goBackText || this.popup.goBackText
                    }
                },
                deep: true
            }
        },
        created() {
            emitter.on('openVrGameIntroModal', () => {
                this.$bvModal.show('vr-game-intro-modal')
                this.getNewCode()
                this.checkIfCompleted()

                this.resultInterval = setInterval(() => {
                    this.checkIfCompleted(false)
                }, 10000)
            })
        },
        mounted() {
            this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
                if (modalId === 'vr-game-intro-modal' && this.resultInterval) {
                    this.errorMessageToShow = ''
                    clearInterval(this.resultInterval)
                    clearInterval(this.otpInterval)
                }
            })
        },
        beforeDestroy() {
            clearInterval(this.otpInterval)
            clearInterval(this.resultInterval)
        },
        methods: {
            hideModal() {
                this.$refs['vr-game-intro-modal'].hide()

                clearInterval(this.resultInterval)
                clearInterval(this.otpInterval)
                this.errorMessageToShow = ''
            },
            isLastTask(mission, taskId) {
                let index = 0
                for (let task of mission.tasks) {
                    if (task.id === taskId) {
                        break
                    }
                    index++
                }
                return index === mission.tasks.length - 1
            },
            openTaskEndModal(pointsWon) {
                emitter.emit('openTaskEndModal', {
                    task: this.task,
                    pointsWon: pointsWon,
                    isLastTask: this.isLastTask(this.mission, this.taskId),
                    missionId: this.missionId,
                    shouldNotRedirect: false,
                    isPlayAgain: false
                })
                this.$router.push(`/missions/${this.missionId}`)
            },
            checkIfCompleted(firstCall = true) {
                taskService.taskStatus(this.taskId, this.otpCode).then((res) => {
                    if (res.data) {
                        if (firstCall) {
                            this.hasBeenPlayed = res.data?.taskCompleted
                        }
                        this.isTaskCompleted = res.data?.taskCompleted
                        if (res.data.taskPoints > 0 && !this.hasBeenPlayed) {
                            this.hideModal()
                            this.openTaskEndModal(res.data.taskPoints)
                        }
                        if (!res.data.taskOtpExpiration) {
                            this.secondsLeft = 0
                            clearInterval(this.otpInterval)
                        }
                    }
                })
            },
            async getNewCode() {
                const recaptchaResponse = await this.recaptcha('TASK_OTP_REQUEST')
                this.loadingOtp = true
                if (recaptchaResponse) {
                    try {
                        const res = await taskService.getOtp(this.taskId, recaptchaResponse)

                        this.otpCode = res.data.otpCode
                        this.secondsLeft = this.getRemainingSecondsFromNow(res.data.expirationDate)
                        this.otpInterval = setInterval(() => {
                            if (this.secondsLeft > 0) {
                                this.secondsLeft -= 1
                            }
                        }, 1000)
                    } catch (error) {
                        this.handleError(error)
                    } finally {
                        this.loadingOtp = false
                    }
                }
            },
            getRemainingSecondsFromNow(datetime) {
                const now = new Date()
                const date = new Date(datetime)
                return Math.floor((date - now) / 1000)
            },
            handleError(error) {
                if (error.response?.data?.code === 'LAST_REQUESTED_OTP_STILL_ACTIVE') {
                    this.errorMessageToShow = 'The last requested code is still active. You can find it in your preferred communications channel inbox.'
                }
            }
        }
    }
</script>
<style>
#vr-game-intro-modal.modal {
    backdrop-filter: blur(2px);
}
</style>
<style lang="scss" scoped>
.modal-body {
    min-height: 400px !important;
    max-width: 500px !important;
}

.header-container {
    background: linear-gradient(180deg, #1b8ebd 0%, #284290 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 170px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    padding: 6rem 1rem 1rem;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.avatar-container {
    position: absolute;
    width: 200px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.avatar-container img {
    width: 100%;
    height: auto;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.body-head,
.time-left {
    font-size: 1.25rem;
    color: #000;
    text-align: center;
    margin: 0 auto;
}

.body-text {
    font-weight: 700;
    font-size: 2.5rem;
    color: #1b8fbd;
    text-align: center;
    margin: 0 auto;
    padding: 1rem;
}

.expired {
    opacity: 0.5;
}

.otp-info {
    font-size: 1rem;
    color: #000;
    text-align: center;
    margin: 0 auto;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    margin: 2rem auto;
}

.custom-btn {
    width: 100%;
    max-width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0 0 0.2rem rgb(0 0 0 / 30%));
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    background: #1b8fbd;
    color: var(--white);
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 1px;
    transition: all 0.35s ease-in-out;
}

.custom-btn:hover {
    background: hsl(197deg 75% 55%);
}

.custom-btn:active {
    transform: scale(0.9);
}

.custom-btn.disabled {
    background: #ccc;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
}

.error-container {
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message {
    color: var(--error);
    font-weight: 700;
    font-size: 0.75rem;
}

.go-back {
    font-weight: 1rem;
    color: #1b8fbd;
    text-decoration: underline;
}

.go-back:hover {
    cursor: pointer;
    color: hsl(197deg 75% 32%);
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
